import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useSliderList() {
  const api = useApi()
  const http = async () => {
    const res = await api.get('/slider')
    return res
  }

  const { data } = useQuery([queryKeys.sliderList], http)

  const sliderList = data?.data?.slider

  return { sliderList }
}

export default useSliderList
