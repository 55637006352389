import { css } from '@emotion/css'
import { Button, Carousel } from 'antd'
import React, { useRef } from 'react'
import arrowLeft from './chevron-left.svg'
import arrowRight from './chevron-right.svg'

function OwnSlider({
  children,
  draggable = true,
  infinite = true,
  autoplay = true,
  dots = true,
  vertical,
  centerMode,
  //   fade = false,
  autoplaySpeed,
  slidesToScroll,
  slidesToShow,
  speed,
  responsive,
  lazyLoad,
  className,
  easing,
  slide,
  arrows = true,
}) {
  const slider = useRef(null)
  const OwnSliderStyles = css`
    position: relative;
    .control-box {
      height: 0;
      width: 100%;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      padding: 4%;

      .btn {
        transform: translateY(-50%);
        /* display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5); */

        width: 5rem;
        img {
          width: 100%;
        }

        &:hover {
          opacity: 0.8;
        }

        &-prev {
        }
        &-next {
        }
      }
    }
  `

  return (
    <div className={OwnSliderStyles}>
      <Carousel
        ref={slider}
        draggable={draggable}
        infinite={infinite}
        autoplay={autoplay}
        dots={dots}
        vertical={vertical | false}
        centerMode={centerMode | false}
        // fade={fade}
        autoplaySpeed={autoplaySpeed | 3000}
        slidesToScroll={slidesToScroll | 1}
        slidesToShow={slidesToShow | 1}
        speed={speed | 500}
        responsive={responsive | null}
        lazyLoad={lazyLoad | null}
        className={className | ''}
        easing={easing | 'linear'}
        slide={slide | 'ul'}
      >
        {children}
      </Carousel>

      {arrows && (
        <div className="control-box">
          <Button
            type="link"
            onClick={() => slider.current.prev()}
            className="btn btn-prev"
            icon={<img src={arrowLeft} alt="arrow" />}
          />
          <Button
            type="link"
            onClick={() => slider.current.next()}
            className="btn btn-next"
            icon={<img src={arrowRight} alt="arrow" />}
          />
        </div>
      )}
    </div>
  )
}

export default OwnSlider
