import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

// function useVideoList() {
//   const api = useApi()
//   const http = async () => {
//     const res = await api.get('/videos')
//     return res
//   }

//   const { data } = useQuery([queryKeys.videosList], http)

//   const videosList = data?.data?.videos
//   const videosListPgn = data?.data?.pagination

//   return { videosList, videosListPgn }
// }

// export default useVideoList

function useVideoList() {
  const api = useApi()
  const http = async ({ pageParam = 0 }) => {
    //    console.log('pageParam...', pageParam)
    const res = await api.get(`/videos?page=${pageParam}`)
    return res
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery([queryKeys.videosList], http, {
      getNextPageParam: (lastPage, pages) => {
        //    console.log('lastPage', lastPage)
        //    console.log('pages', pages)
        const pgn = lastPage?.data?.pagination
        if (pgn?.next) return pgn?.current + 1

        return undefined
      },
    })

  // console.log('hasNextPage', hasNextPage)
  const videosList = data?.pages?.flatMap(page => page?.data?.videos)

  // const videosListPgn = data?.data?.pagination

  return {
    videosList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  }
}

export default useVideoList
