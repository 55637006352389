import { css } from '@emotion/css'
import { Button, Col, Row } from 'antd'
import React from 'react'
import CategoriesItem from './CategoriesItem'
import useProjCatList from 'api-hooks/project-categories/useProjCatList'
import useSearchContext from 'hooks/useSearchContext'

function CategoriesWrapper({ home }) {
  const CategoriesWrapperStyles = css`
    max-width: 100%;
    padding: 4rem 0;
    .btn-clr {
      margin-bottom: 2rem;
    }
  `

  const { projCatList } = useProjCatList()
  const { isCatSearchMod, setIsCatSearchMod, categoryRes } = useSearchContext()
  // console.log(projCatList)

  return (
    <div className={CategoriesWrapperStyles}>
      {isCatSearchMod ? (
        <>
          <Button
            type="primary center"
            danger
            className="btn-clr"
            onClick={() => setIsCatSearchMod(false)}
          >
            clear search
          </Button>
          <Row gutter={[{ lg: 26 }, 26]}>
            {categoryRes?.map(ele => (
              <Col key={ele?.id} xs={24} lg={12}>
                <CategoriesItem ele={ele} />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <Row gutter={[{ lg: 26 }, 26]}>
          {home
            ? projCatList?.slice(0, 5)?.map((ele, i) => (
                <Col key={ele?.id} xs={24} lg={i === 4 ? 24 : 12}>
                  <CategoriesItem ele={ele} />
                </Col>
              ))
            : projCatList?.map(ele => (
                <Col key={ele?.id} xs={24} lg={12}>
                  <CategoriesItem ele={ele} />
                </Col>
              ))}
        </Row>
      )}
    </div>
  )
}

export default CategoriesWrapper
