export const queryKeys = {
  projectCategoriesList: 'project-categories-list',
  photoCategoriesList: 'photo-categories-list',
  videosList: 'videos-list',
  awardsList: 'awards-list',
  projectsList: 'projects-list',
  projectsItem: 'projects-item',
  photosList: 'photos-list',
  photosItem: 'photos-item',
  blogsList: 'blogs-list',
  blogsItem: 'blogs-item',
  sliderList: 'slider-list',
}
