import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function usePhotosList(id) {
  const api = useApi()
  const http = async () => {
    if (!id) return null

    const fd = new FormData()
    fd.append('photo_category_id', id)
    const res = await api.post('/photos', fd)
    return res
  }

  const { data } = useQuery([queryKeys.photosList, id], http)

  const photosList = data?.data?.photos
  const photosListPgn = data?.data?.pagination

  return { photosList, photosListPgn }
}

export default usePhotosList
