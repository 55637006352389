import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useAwardsList() {
  const api = useApi()
  const http = async () => {
    const res = await api.get('/awards')
    return res
  }

  const { data, isLoading } = useQuery([queryKeys.awardsList], http)

  const awardsList = data?.data?.awards
  const awardsListPgn = data?.data?.pagination

  return { awardsList, awardsListPgn, isLoading }
}

export default useAwardsList
