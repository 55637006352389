import useIsScreenGreater from 'hooks/useIsScreenGreater'
import React, { useState } from 'react'
import { createContext } from 'react'
export const layoutContext = createContext({})

function Layout({ children }) {
  const isBigScr = useIsScreenGreater(1200)
  const isMidScr = useIsScreenGreater(992)
  const isSmlScr = useIsScreenGreater(768)

  const [isSubscribePopup, setIsSubscribePopup] = useState(false)

  const openSubscribePopup = () => {
    const isSubscribePopupShown = sessionStorage.getItem(
      'isSubscribePopupShown'
    )
    if (isSubscribePopupShown) return
    setIsSubscribePopup(true)
    sessionStorage.setItem('isSubscribePopupShown', true)
  }
  const closeSubscribePopup = () => {
    setIsSubscribePopup(false)
  }

  return (
    <layoutContext.Provider
      value={{
        isBigScr,
        isMidScr,
        isSmlScr,
        isSubscribePopup,
        openSubscribePopup,
        closeSubscribePopup,
      }}
    >
      {children}
    </layoutContext.Provider>
  )
}

export default Layout
