import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useProjList(id) {
  const api = useApi()

  const http = async () => {
    if (!id) return null

    const fd = new FormData()
    fd.append('project_category_id', id)
    const res = await api.post('/projects', fd)
    return res
  }

  const { data } = useQuery([queryKeys.projectsList, id], http)

  const projList = data?.data?.projects
  const projListPgn = data?.data?.pagination

  return { projList, projListPgn }
}

export default useProjList
