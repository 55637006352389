import { css } from '@emotion/css'
import OwnVideo from 'components/own/OwnVideo/OwnVideo'
import React from 'react'

function SingleVideo({ ele, url, videoOnPlay, setVideoOnPlay }) {
  const SingleVideoStyles = css`
    /* background-color: #eee; */
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    .title {
      font-weight: 450;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      color: #000000;
    }
  `

  const handleOnplay = () => {
    setVideoOnPlay(ele?.video)
  }
  return (
    <div className={SingleVideoStyles}>
      <OwnVideo
        url={ele?.video}
        playing={videoOnPlay === ele?.video}
        controls
        width="32.3rem"
        height="27.2rem"
        onplay={handleOnplay}
      />
      <div className="title">{ele?.name}</div>
    </div>
  )
}

export default SingleVideo
