import cls from 'classnames'
import { css } from '@emotion/css'

function OwnOverlay({ visible, onClick, opacity, className, style }) {
  const OwnOverlayStyles = css`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: #000;
    opacity: ${opacity || 0.5};
  `
  return (
    <div
      className={cls(OwnOverlayStyles, className)}
      style={{ display: visible ? 'block' : 'none', ...style }}
      onClick={onClick}
    />
  )
}

export default OwnOverlay
