import React, { useState } from 'react'
import { createContext } from 'react'
export const searchContext = createContext({})

function Search({ children }) {
  const [categoryRes, setCategoryRes] = useState([])
  const [isCatSearchMod, setIsCatSearchMod] = useState(false)

  return (
    <searchContext.Provider
      value={{ categoryRes, setCategoryRes, isCatSearchMod, setIsCatSearchMod }}
    >
      {children}
    </searchContext.Provider>
  )
}

export default Search
