import React from 'react'
import { Link } from 'react-router-dom'
import logo_img from 'assets/images/logo.png'
import { css } from '@emotion/css'

function BrandLogo() {
  const BrandLogoStyles = css`
    /* width: 7.1rem; */
    /* z-index: 9; */

    a {
      display: flex;
      width: 6.4rem;
      height: 5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  `
  return (
    <div className={BrandLogoStyles}>
      <Link to="/">
        <img src={logo_img} alt="Loak" />
      </Link>
    </div>
  )
}

export default BrandLogo
