import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import React from 'react'

function TextWrapper({ ele, title, text, align }) {
  const TextWrapperStyles = css`
    /* padding: 4rem 0; */
    width: 90rem;
    max-width: 100%;
    margin: 10rem auto 6rem;

    .title {
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.4rem;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 3.8rem;
    }

    p {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 204%;
      text-align: ${align || 'start'};
      color: #000000;
      margin-bottom: 3.8rem;
    }
  `
  // console.log(ele)
  if (!ele)
    return (
      <div className={TextWrapperStyles}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            {title && <div className="title">{title}</div>}
            <p>{text}</p>
          </Col>
        </Row>
      </div>
    )
  return (
    <div className={TextWrapperStyles}>
      <Row gutter={[20, 20]}>
        {ele?.type_options?.map((item, i) => (
          <Col key={i} span={24}>
            {title && <div className="title">{title}</div>}
            <p>{item?.text}</p>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default TextWrapper
