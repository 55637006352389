import { layoutContext } from 'contexts/Layout'
import { useContext } from 'react'

function useLayout() {
  const {
    isBigScr,
    isMidScr,
    isSmlScr,
    isSubscribePopup,
    openSubscribePopup,
    closeSubscribePopup,
  } = useContext(layoutContext)
  return {
    isBigScr,
    isMidScr,
    isSmlScr,
    isSubscribePopup,
    openSubscribePopup,
    closeSubscribePopup,
  }
}

export default useLayout
