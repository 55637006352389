import { css } from '@emotion/css'
import React from 'react'
import { Link } from 'react-router-dom'
import cal_img from 'assets/images/icons/calendar-g.svg'

function SingleBlog({ ele }) {
  const SingleBlogStyles = css`
    width: 42.2rem;
    max-width: 100%;
    margin: auto;
    min-height: 41.9rem;
    padding: 3rem;
    background-image: linear-gradient(
        176.62deg,
        rgba(255, 255, 255, 0) 70%,
        rgba(255, 255, 255, 0.9) 100%
      ),
      url(${ele?.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .box {
      display: flex;
      flex-direction: column;
      gap: 1.1rem;
      font-weight: 400;
      color: #000;

      .title {
        font-size: 1.4rem;
        line-height: 1.7rem;
        transition: 0.1s;
      }
      .date {
        display: flex;
        align-items: center;
        gap: 1rem;

        font-size: 1.2rem;
        line-height: 1.5rem;

        img {
          width: 2.4rem;
        }
      }
    }

    &:hover {
      .title {
        color: #000;
        font-weight: 500;
      }
    }
  `
  return (
    <Link to={ele?.id?.toString()} className={SingleBlogStyles}>
      <div className="box">
        <div className="title">{ele?.name}</div>
        <div className="date">
          <img src={cal_img} alt="date" />
          {ele?.createdDate}
        </div>
      </div>
    </Link>
  )
}

export default SingleBlog
