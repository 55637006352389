import { css } from '@emotion/css'
import CategoriesWrapper from 'components/CategoriesWrapper/CategoriesWrapper'
import HomeCover from './HomeCover'

function Home() {
  const HomeStyles = css``

  return (
    <div className={HomeStyles}>
      <HomeCover />
      <CategoriesWrapper home />
    </div>
  )
}
export default Home
