import { css } from '@emotion/css'
import React from 'react'
import cal_img from 'assets/images/icons/calendar-g.svg'
import useBlogsItem from 'api-hooks/blogs/useblogsItem'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'antd'
import TypeOptionsWrapper from 'components/TypeOptionsWrapper/TypeOptionsWrapper'
import OwnResult from 'components/own/OwnResult/OwnResult'

function BlogDetails() {
  const BlogDetailsStyles = css`
    padding: 15rem 0;

    .page-header {
      padding: 7.6rem 0;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 8rem;

      .title {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #000000;
      }

      .text {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #000000;
      }

      .date {
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          width: 2.4rem;
        }
      }
    }
  `
  const { blogId } = useParams()
  const { blogItem } = useBlogsItem(blogId)

  return (
    <div className={BlogDetailsStyles}>
      <div className="container">
        <div className="page-header">
          <div className="title">{blogItem?.name}</div>
          <div className="date">
            <img src={cal_img} alt="date" />
            <div className="text">{blogItem?.createdDate}</div>
          </div>
        </div>

        <div className="sections-wrapper">
          {blogItem?.sections?.length ? (
            <Row gutter={[0, 60]}>
              {blogItem?.sections?.map(ele => (
                <Col key={ele?.id} span={24}>
                  <TypeOptionsWrapper ele={ele} />
                </Col>
              ))}
            </Row>
          ) : (
            <OwnResult title={'No sections yet!'} />
          )}
        </div>
      </div>
    </div>
  )
}

export default BlogDetails
