import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import React from 'react'
import SingleBlog from './SingleBlog'
import useBlogsList from 'api-hooks/blogs/useBlogsList'
import OwnResult from 'components/own/OwnResult/OwnResult'

function Blogs() {
  const BlogsStyles = css`
    padding: 15rem 0;
  `

  const { blogsList } = useBlogsList()
  // console.log(blogsList)
  return (
    <div className={BlogsStyles}>
      {!!blogsList?.length ? (
        <Row gutter={[20, 20]} justify="center">
          {blogsList?.map(ele => (
            <Col key={ele?.id}>
              <SingleBlog ele={ele} />
            </Col>
          ))}
        </Row>
      ) : (
        <OwnResult title="No Blogs Yet!" />
      )}
    </div>
  )
}

export default Blogs
