import { css } from '@emotion/css'
import { Col, Image, Row } from 'antd'
import React from 'react'

function ImagesWrapper({ ele, images }) {
  const ImagesWrapperStyles = css`
    /* padding: 4rem 0; */
    .ant-col {
    }
    .image-wrapper {
      width: 100%;
      /* height: 100%;
      min-height: 42rem;
      max-height: 50rem; */

      .image {
        max-width: 100%;
        height: 100%;
        /* object-fit: cover; */
        object-fit: contain;
      }
    }
  `
  // console.log(ele)

  if (ele?.type === 'one image' || ele?.type === 'One image')
    return (
      <div className={ImagesWrapperStyles}>
        <Row gutter={[15, 15]}>
          {ele?.type_options?.map(item => (
            <Col key={item?.image} span={24}>
              <Image
                wrapperClassName="image-wrapper"
                className="image"
                src={item?.image}
                alt="project"
              />
            </Col>
          ))}
        </Row>
      </div>
    )

  if (ele?.type === 'One image Left with a two third image Right.')
    return (
      <div className={ImagesWrapperStyles}>
        <Row gutter={[15, 15]}>
          {ele?.type_options?.map((item, i) => {
            return (
              <Col key={item?.image} xs={24} lg={i === 0 ? 8 : 16}>
                <Image
                  wrapperClassName="image-wrapper"
                  className="image"
                  src={item?.image}
                  alt="project"
                />
              </Col>
            )
          })}
        </Row>
      </div>
    )

  if (
    ele?.type === 'A two third image Left with one image Right.' ||
    ele?.type === 'A two third image Left with one image Right'
  )
    return (
      <div className={ImagesWrapperStyles}>
        <Row gutter={[15, 15]}>
          {ele?.type_options?.map((item, i) => {
            return (
              <Col key={item?.image} xs={24} lg={i === 1 ? 8 : 16}>
                <Image
                  wrapperClassName="image-wrapper"
                  className="image"
                  src={item?.image}
                  alt="project"
                />
              </Col>
            )
          })}
        </Row>
      </div>
    )

  if (ele?.type === 'Three images')
    return (
      <div className={ImagesWrapperStyles}>
        <Row gutter={[15, 15]}>
          {ele?.type_options?.map(item => (
            <Col key={item?.image} xs={24} lg={8}>
              <Image
                wrapperClassName="image-wrapper"
                className="image"
                src={item?.image}
                alt="project"
              />
            </Col>
          ))}
        </Row>
      </div>
    )

  if (images)
    return (
      <div className={ImagesWrapperStyles}>
        <Row gutter={[15, 15]}>
          {images?.map((img, i) => (
            <Col key={i} xs={24} lg={8}>
              <Image
                wrapperClassName="image-wrapper"
                className="image"
                src={img}
                alt="project"
              />
            </Col>
          ))}
        </Row>
      </div>
    )
}

export default ImagesWrapper
