import { css } from '@emotion/css'
import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import SingleAward from './SingleAward'
import useAwardsList from 'api-hooks/awards/useAwardsList'
import OwnResult from 'components/own/OwnResult/OwnResult'

function Awards() {
  const AwardsStyles = css`
    max-width: 90rem;
    margin: auto;

    .btn-load-more {
      margin-top: 8rem;
    }
  `
  const { awardsList, isLoading } = useAwardsList()
  // console.log(awardsList)

  return (
    <div className="container">
      <div className={AwardsStyles}>
        {isLoading ? (
          <Skeleton />
        ) : !awardsList?.length ? (
          <OwnResult title={'No Data Added!'} />
        ) : (
          <Row gutter={[70, 60]} justify="center">
            {awardsList?.map(ele => (
              <Col key={ele?.id}>
                <SingleAward ele={ele} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  )
}

export default Awards
