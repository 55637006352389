import { css } from '@emotion/css'
import { Button, Col, Form, Input, Row } from 'antd'
import useSubscriber from 'api-hooks/subscriber/useSubscriber'
import useLayout from 'contexts/hooks/useLayout'
import useFormRules from 'hooks/useFormRules'
import React from 'react'

function SubscribeForm() {
  const SubscribeFormStyles = css`
    width: 28.2rem;
    height: 4.1rem;
    padding: 0.4rem;
    border: solid 1px rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-item {
      position: relative;
      margin-bottom: 0;
      .ant-form-item-explain {
        position: absolute;
        top: 110%;
        left: 0;
        div {
          font-size: 1.2rem;
        }
      }
    }

    input {
      padding: 0.5rem 1rem;
      border: none;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.58;
      color: #000;
      &::placeholder {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.58;
        text-transform: capitalize;
        color: #7d7d7d;
      }
    }

    button {
      min-height: auto;
      max-height: 3.4rem;
      min-width: 5.1rem;
      padding: 0.2rem 0.5rem;
      span {
        font-size: 1.2rem;
      }
    }
  `

  const [form] = Form.useForm()
  const rules = useFormRules()
  const { closeSubscribePopup } = useLayout()

  const { subscriber } = useSubscriber()

  const handleSubscribe = values => {
    //    console.log(values)
    const fd = new FormData()
    fd.append('email', values?.email)
    subscriber(fd)
    closeSubscribePopup()
    form?.resetFields()
  }
  return (
    <Form
      className={SubscribeFormStyles}
      form={form}
      onFinish={handleSubscribe}
    >
      <Row wrap={false} style={{ width: '100%' }}>
        <Col flex={'auto'}>
          <Form.Item name="email" rules={rules.email} className="form-item">
            <Input placeholder="Enter your email" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item className="form-item">
            <Button type="primary" htmlType="submit">
              Ok
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default SubscribeForm
