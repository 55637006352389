import { Result } from 'antd'
import React from 'react'
import notFound_img from 'assets/images/own/OwnResult_1.svg'
import { css } from '@emotion/css'

function OwnResult({
  icon,
  iconWidth,
  title,
  titleStyle,
  extra,
  search,
  className,
  ...rest
}) {
  const OwnResultStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // gap: 2rem;
    .title {
      font-size: 2.6rem;
      text-transform: capitalize;
      max-width: 55rem;
    }

    .icon {
      width: ${iconWidth || '7.2rem'};
    }

    .ant-result-icon {
      span {
        font-size: 5rem;
      }
    }
  `
  return (
    <Result
      icon={search && <img src={notFound_img} className="icon" alt="icon" />}
      title={
        <span className="title" style={titleStyle}>
          {title}
        </span>
      }
      extra={extra}
      // className={cls(styles.result, className)}
      className={OwnResultStyles}
      {...rest}
    />
  )
}

export default OwnResult
