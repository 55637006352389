import { Link } from 'react-router-dom'
import styles from './NotFound.module.scss'
import logo from 'assets/images/logo.png'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.page_wrapper}>
      <div className="container">
        <div className={styles.page_inner}>
          <div className={styles.title}>{t('page not found')}</div>
          <div className={styles.description}>
            <p>{t("We couldn't find what you were looking for.")}</p>
            <p>
              {t(
                'Please contact the owner of the site that linked you to the original URL and let them know their link is broken.'
              )}
            </p>
          </div>
          <Link to="/">
            <img src={logo} title={t('go home')} alt="brand" />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
