import { css } from '@emotion/css'
import { Tabs } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Awards from './Awards/Awards'
import Photos from './Photos/Photos'
import Videos from './Videos/Videos'

function MediaAndAwards() {
  const navigate = useNavigate()
  const { keyTab } = useParams()
  const [keyTabAct, setKeyTabAct] = useState(keyTab)

  const MediaAndAwardsStyles = css`
    padding: 16rem 0;

    .tabs {
      .ant-tabs-nav {
        margin-bottom: 9.2rem;
        &::before {
          display: none;
        }
      }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-size: 1.2rem;
          font-weight: 500;
          text-transform: capitalize;
          padding: 0.8rem 0;
          text-align: center;
          color: #7d7d7d;

          &.ant-tabs-tab-active {
            div {
              text-transform: uppercase;
              color: #000;
            }
          }
        }
      }
    }
  `
  return (
    <div className={MediaAndAwardsStyles}>
      <Tabs
        activeKey={keyTabAct || 'videos'}
        onChange={key => {
          setKeyTabAct(key)
          navigate(`/media-and-awards/${key}`)
        }}
        // size="large"
        tabBarGutter="6rem"
        centered
        className="tabs"
      >
        <Tabs.TabPane tab="videos" key="videos">
          <Videos />
        </Tabs.TabPane>
        <Tabs.TabPane tab="photos" key="photos">
          <Photos />
        </Tabs.TabPane>
        <Tabs.TabPane tab="awards" key="awards">
          <Awards />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default MediaAndAwards
