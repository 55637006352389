import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useBlogsList() {
  const api = useApi()
  const http = async () => {
    const res = await api.get('/blogs')
    return res
  }

  const { data } = useQuery([queryKeys.blogsList], http)

  const blogsList = data?.data?.blogs
  const blogsListPgn = data?.data?.pagination

  return { blogsList, blogsListPgn }
}

export default useBlogsList
