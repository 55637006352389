import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useBlogsItem(id) {
  const api = useApi()

  const http = async () => {
    if (!id) return null

    const res = await api.get(`/show/blog?id=${id}`)
    return res
  }

  const { data } = useQuery([queryKeys.blogsItem, id], http)

  const blogItem = data?.data?.['show blog']

  return { blogItem }
}

export default useBlogsItem
