import { useMutation } from '@tanstack/react-query'
import useSearchContext from 'hooks/useSearchContext'
import { toast } from 'react-toastify'
import useApi from 'hooks/useApi'

function useProjCatSrch() {
  const { setIsCatSearchMod, setCategoryRes } = useSearchContext()
  const api = useApi()
  const http = async query => {
    const res = await api.get(`/search/category?category_name=${query}`)
    if (res?.code === 200) {
      setIsCatSearchMod(true)
      setCategoryRes(res?.data?.['search project category'])
      // console.log(res.data)
    }
    return res
  }

  const { mutate, data } = useMutation(http, {
    onSuccess: res => {
      // console.log('res', res)
      const length = res?.data?.['search project category']?.length
      toast.info(length + ' categories found')
    },
  })

  const projCatSrchRes = data?.data?.['search project category']
  const projCatSrchResPgn = data?.data?.pagination
  // console.log(projCatSrchRes)
  return { projCatSrch: mutate, projCatSrchRes, projCatSrchResPgn }
}

export default useProjCatSrch
