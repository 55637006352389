import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function usePhotosItem(id) {
  const api = useApi()
  const http = async () => {
    if (!id) return null

    const res = await api.get(`/show/photo?id=${id}`)
    return res
  }

  const { data } = useQuery([queryKeys.photosItem, id], http)

  const photoItem = data?.data?.['show photo']
  // console.log('photoItem', photoItem)
  return { photoItem }
}

export default usePhotosItem
