import { css } from '@emotion/css'
import React from 'react'
import { Link } from 'react-router-dom'

function SinglePhoto({ ele }) {
  const SinglePhotoStyles = css`
    /* width: 67rem; */
    max-width: 100%;
    margin: auto;
    height: 62.6rem;
    padding: 3rem;
    background-image: url(${ele?.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    span {
      font-size: 2rem;
      line-height: 2.4rem;
      letter-spacing: 0.03em; /* text-align: center; */
      text-transform: capitalize;
      color: #fff;
      transition: 0.1s;
      /* background-color: rgba(0, 0, 0, 0.4); */
    }
    &:hover {
      color: #fff;
      font-weight: 500;
    }
  `
  return (
    <Link to={ele?.id?.toString()} className={SinglePhotoStyles}>
      <span>{ele?.name}</span>
    </Link>
  )
}

export default SinglePhoto
