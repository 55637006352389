import { css } from '@emotion/css'
import React from 'react'
import ProjectCover from './ProjectCover'
import { useParams } from 'react-router-dom'
import useProjItem from 'api-hooks/projects/useProjItem'
import { Col, Row } from 'antd'
import TypeOptionsWrapper from 'components/TypeOptionsWrapper/TypeOptionsWrapper'
import OwnResult from 'components/own/OwnResult/OwnResult'

function Project() {
  const ProjectStyles = css`
    display: flex;
    flex-direction: column;
    /* gap: 5rem; */

    .sections-wrapper {
      margin: 1.5rem 0;
    }
  `

  const { projectId } = useParams()
  const { projItem } = useProjItem(projectId)
  console.log(projItem)
  return (
    <div className={ProjectStyles}>
      <ProjectCover
        img={projItem?.image}
        title={projItem?.name}
        project={projItem?.project_category}
        location={projItem?.location}
        area={projItem?.area}
      />

      <div className="sections-wrapper">
        {projItem?.sections?.length ? (
          <Row gutter={[0, 15]}>
            {projItem?.sections?.map(ele => (
              <Col key={ele?.id} span={24}>
                <TypeOptionsWrapper ele={ele} />
              </Col>
            ))}
          </Row>
        ) : (
          <OwnResult title={'No sections yet!'} />
        )}
      </div>
    </div>
  )
}

export default Project
