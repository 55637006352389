import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import usePhotosItem from 'api-hooks/photos/usePhotosItem'
import OwnResult from 'components/own/OwnResult/OwnResult'
import TypeOptionsWrapper from 'components/TypeOptionsWrapper/TypeOptionsWrapper'
import React from 'react'
import { useParams } from 'react-router-dom'

function SinglePhotoDetails() {
  const SinglePhotoDetailsStyles = css`
    padding: 15rem 0;

    .page-header {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 4rem 8rem;
      padding: 9rem 0;
      .title {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #000000;
      }

      .colors-wrapper {
        display: flex;
        align-items: center;
        gap: 1.4rem;
        .text {
          font-weight: 300;
          font-size: 1.6rem;
          line-height: 2rem;
          letter-spacing: 0.03em;
          text-transform: capitalize;
          color: #000000;
        }
        .colors-list {
          display: flex;
          align-items: center;
          gap: 0.9rem;

          span {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .page-body {
    }
  `
  const { photoId } = useParams()
  const { photoItem } = usePhotosItem(photoId)
  // console.log(photoItem)
  return (
    <div className={SinglePhotoDetailsStyles}>
      <div className="page-header">
        <div className="title">{photoItem?.photo_category}</div>
        {!!photoItem?.Colours?.length && (
          <div className="colors-wrapper">
            <div className="text">Color :</div>
            <div className="colors-list">
              {photoItem?.Colours?.map((ele, i) => (
                <span
                  key={ele?.colour + i}
                  style={{ backgroundColor: ele?.colour }}
                ></span>
              ))}
            </div>
          </div>
        )}
      </div>

      {!photoItem?.sections?.length ? (
        <OwnResult title="There are no pictures yet!" />
      ) : (
        <div className="page-body">
          <Row gutter={[0, 40]}>
            {photoItem?.sections?.map(ele => (
              <Col key={ele?.id} span={24}>
                <TypeOptionsWrapper key={ele?.id} ele={ele} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  )
}

export default SinglePhotoDetails
