import { css } from '@emotion/css'
import React from 'react'

function ProjectCover({ img, title, project, location, area }) {
  const ProjectCoverStyles = css`
    height: 62.6rem;
    padding: 10rem 3rem;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    .box {
      display: flex;
      flex-direction: column;
      gap: 10.2rem;

      .title {
        font-weight: 400;
        font-size: 4rem;
        line-height: 4.9rem;
        text-align: center;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        color: #ffffff;
      }

      .text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.8rem;
        .text {
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 1.3;
          text-align: center;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #ffffff;
          /* background-color: rgba(0, 0, 0, 0.4); */
        }
      }
    }
  `
  return (
    <div className={ProjectCoverStyles}>
      <div className="box">
        <div className="title">{title}</div>
        <div className="text-wrapper">
          <div className="text">type: {project}</div>
          <div className="text">location: {location}</div>
          <div className="text">area: {area}</div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCover
