import './styles/globals.scss'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './Routes'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import ScrollToTop from 'components/common/ScrollToTop'
import OnlyOnStart from 'components/common/OnlyOnStart'
import SubscribePopup from 'components/SubscribePopup/SubscribePopup'
import QueryProvider from 'services/react-query'
import Loading from 'components/common/Loading'
import { ToastContainer } from 'react-toastify'
import ContextProvider from 'contexts'

function App() {
  return (
    <QueryProvider>
      <ContextProvider>
        <Header />
        <Routes />
        <Footer />
        <ToastContainer style={{ fontSize: '1.4rem' }} />
        <ScrollToTop />
        <OnlyOnStart wait={4000} />
        <SubscribePopup />
        <Loading />
      </ContextProvider>
    </QueryProvider>
  )
}

export default App
