import { css } from '@emotion/css'
import React from 'react'
import { Link } from 'react-router-dom'

function ProjectLink({ ele }) {
  const CategoriesItemStyles = css`
    /* width: 67rem; */
    max-width: 100%;
    margin: auto;
    height: 62.6rem;
    padding: 3rem;
    background-image: url(${ele?.image});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2rem;
    letter-spacing: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    transition: 0.1s;

    &:hover {
      color: #fff;
      font-weight: 600;
    }
  `
  return (
    <Link to={`${ele?.id}`} className={CategoriesItemStyles}>
      {ele?.name}
    </Link>
  )
}

export default ProjectLink
