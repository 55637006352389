import { css } from '@emotion/css'
import React from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
// import useHeader from 'contexts/hooks/useHeader'

function Dropdown({ items, openKeys = [] }) {
  // const { isScrollUp } = useHeader()
  // console.log(items)

  const DropdownStyles = css`
    position: absolute;
    /* top: isScrollUp ? '8rem' : '17.4rem'}; */
    top: 8rem;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      #fffefe 0%,
      #fffefe 0%,
      #fffefe 0%
    );
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.1);
    width: 100vw;

    transition: 0.3s cubic-bezier(0.5, 0, 0.5, 1.3);
    overflow: auto;
    display: flex;
    transform: scaleY(0);
    transform-origin: top;

    &:hover,
    &.active {
      transform: scaleY(1);
      padding: 5rem 4rem;
    }

    .menu-list {
      display: flex;
      gap: 9rem;
      justify-content: center;
      margin: auto;

      .menu-list-item {
        .menu-title {
          font-size: 1.4rem;
          font-weight: 500;
          text-transform: capitalize;
          color: #000;
          white-space: nowrap;
          min-width: 9.6rem;
          padding: 1rem 0;
          border-bottom: 1px solid #bcbcbc;
        }
      }
    }
    .submenu-list {
      padding: 1.2rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .menu-list-item {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.89;
        text-transform: capitalize;
        color: #1e1e1e;
        white-space: nowrap;
      }
    }

    .submenu-list-item a {
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid transparent;
      &:hover {
        color: #000;
        border-color: #000;
      }
    }
  `

  // console.log('openKeys', openKeys)
  return (
    <div
      className={cls(DropdownStyles, {
        active: openKeys?.includes('our-work'),
      })}
    >
      <ul className="menu-list">
        {items?.map(item => {
          if (!item?.children?.length) return null

          return (
            <li key={item.id} className="menu-list-item">
              <div className="menu-title">{item?.name}</div>
              <ul className="submenu-list">
                {item?.children?.map(sub => (
                  <li key={sub.id} className="submenu-list-item">
                    <Link to={`/our-work/${item.id}/${sub.id}`}>
                      {sub?.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Dropdown
