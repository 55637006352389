import { css } from '@emotion/css'
import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import SinglePhoto from './SinglePhoto'
import usePhotoCatList from 'api-hooks/photo-categories/usePhotoCatList'
import OwnResult from 'components/own/OwnResult/OwnResult'

function Photos() {
  const PhotosStyles = css``

  const { photoCatList, isLoading } = usePhotoCatList()

  // console.log(photoCatList)
  return (
    <div className={PhotosStyles}>
      {isLoading ? (
        <Skeleton />
      ) : !photoCatList?.length ? (
        <OwnResult title={'No Data Added!'} />
      ) : (
        <Row gutter={[{ lg: 26 }, 26]}>
          {photoCatList?.map(ele => (
            <Col key={ele?.id} xs={24} lg={12}>
              <SinglePhoto ele={ele} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  )
}

export default Photos
