import { css } from '@emotion/css'
import React, { useEffect, useState } from 'react'
import vid from 'assets/videos/start-video.MP4'
// import vid from 'assets/videos/SampleVideo_360x240_1mb.mp4'

function OnlyOnStart({ wait = 3000, allTime }) {
  const [isVisible, setIsVisible] = useState(true)
  const OnlyOnStartStyles = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    display: ${allTime || isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;

    .content {
      width: 60rem;
      height: 28rem;
      overflow: hidden;

      position: relative;
      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-53%, -50%);
        width: 60rem;
        height: 42rem;
      }
    }
  `

  useEffect(() => {
    setTimeout(() => setIsVisible(false), wait)
  }, [wait])

  return (
    <div className={OnlyOnStartStyles}>
      <div className="content">
        <video src={vid} autoPlay={true} muted loop />
      </div>
    </div>
  )
}

export default OnlyOnStart
