import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useProjItem(id) {
  const api = useApi()
  const http = async () => {
    if (!id) return null

    const res = await api.get(`/show/project?id=${id}`)
    return res
  }

  const { data } = useQuery([queryKeys.projectsItem, id], http)

  const projItem = data?.data?.['show project']

  return { projItem }
}

export default useProjItem
