import { searchContext } from 'contexts/Search'
import { useContext } from 'react'

function useSearchContext() {
  const { categoryRes, setCategoryRes, isCatSearchMod, setIsCatSearchMod } =
    useContext(searchContext)

  return { categoryRes, setCategoryRes, isCatSearchMod, setIsCatSearchMod }
}

export default useSearchContext
