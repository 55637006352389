import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function useProjCatList() {
  const api = useApi()
  const http = async () => {
    const res = await api.get('/project/categories')
    return res
  }

  const { data } = useQuery([queryKeys.projectCategoriesList], http)

  const projCatList = data?.data?.['project categories']
  const projCatListPgn = data?.data?.pagination

  return { projCatList, projCatListPgn }
}

export default useProjCatList
