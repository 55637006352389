import { useCallback, useEffect, useState } from 'react'

function useScrollY(scrollUp, scrollDown) {
  const [isScrollUp, setIsScrollUp] = useState(false)
  const [isScrollDown, setIsScrollDown] = useState(false)
  const [posY, setposY] = useState(window.scrollY)
  let oldScrollY = 0

  const observeScroll = useCallback(() => {
    setposY(window.scrollY)
    if (window.scrollY > oldScrollY) {
      setIsScrollDown(true)
      setIsScrollUp(false)
    } else {
      setIsScrollDown(false)
      setIsScrollUp(true)
    }

    //
    oldScrollY = window.scrollY
  }, [])

  useEffect(() => {
    // SET VALUES IN START
    if (scrollUp) setIsScrollUp(true)
    if (scrollDown) setIsScrollDown(true)

    //
    window.addEventListener('scroll', observeScroll)

    // CLEAN UP
    return () => window.removeEventListener('scroll', observeScroll)
  }, [observeScroll, scrollUp, scrollDown])

  return { isScrollUp, isScrollDown, posY }
}

export default useScrollY
