import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/react-query/queryKeys'
import useApi from 'hooks/useApi'

function usePhotoCatList() {
  const api = useApi()
  const http = async () => {
    const res = await api.get('/photo/categories')
    return res
  }

  const { data, isLoading } = useQuery([queryKeys.photoCategoriesList], http)

  const photoCatList = data?.data?.['photo categories']
  const photoCatListPgn = data?.data?.pagination

  return { photoCatList, photoCatListPgn, isLoading }
}

export default usePhotoCatList
