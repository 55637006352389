import { css } from '@emotion/css'
import { Button, Col, Form, Input, Row } from 'antd'
import React from 'react'
import contactImg from 'assets/images/contact.jpg'
import useFormRules from 'hooks/useFormRules'
import useMsgSend from 'api-hooks/message/useMsgSend'
import { toast } from 'react-toastify'

function ContactUs() {
  const ContactUsStyles = css`
    padding: 17rem 0 10rem;

    .image-wrapper {
      text-align: center;
      max-height: 63.5rem;
      img {
      }
    }
  `
  const [form] = Form.useForm()
  const rules = useFormRules()
  const { msgSend } = useMsgSend()

  async function handleContactUs(values) {
    //    console.log(values)
    const formData = new FormData()

    for (const key in values) {
      formData.append(key, values[key])
    }
    msgSend(formData)
  }

  return (
    <div className={ContactUsStyles}>
      <Row gutter={[0, 60]} align="middle">
        <Col xs={24} lg={{ span: 13, order: 2 }}>
          <div className="image-wrapper">
            <img src={contactImg} alt="contact us" />
          </div>
        </Col>
        <Col xs={24} lg={11}>
          <div className="form-wrapper">
            <div className="form-header">
              <div className="title">Contact Us</div>
              <div className="sub-title">Feel free to send us at anytime</div>
            </div>

            <Form
              form={form}
              name="basic"
              layout="vertical"
              requiredMark={false}
              onFinish={handleContactUs}
              autoComplete="off"
              className="form-hero"
            >
              <Form.Item
                name="name"
                rules={rules.required}
                className="form-item"
              >
                <Input placeholder="Your name" className="form-input" />
              </Form.Item>
              <Form.Item name="email" rules={rules.email} className="form-item">
                <Input placeholder="Enter your email" className="form-input" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={rules.phoneNumber}
                className="form-item"
              >
                <Input placeholder="Phone number" className="form-input" />
              </Form.Item>
              <Form.Item
                name="message"
                rules={rules.required}
                className="form-item"
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Message"
                  className="form-input"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  className="btn-submit"
                >
                  Sent
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ContactUs
