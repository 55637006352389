import useIsScreenGreater from 'hooks/useIsScreenGreater'
import useScrollY from 'hooks/useScrollY'
import React, { useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { createContext } from 'react'
export const headerContext = createContext({})

// constants
const bigScreenStart = 992
function Header({ children }) {
  const { isScrollUp, posY } = useScrollY('scrollDown')
  const isScreenGreater = useIsScreenGreater(bigScreenStart)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isSearchVisible, setIsSearchVisible] = useState(false)
  const [isActiveHeaderBg, setIsActiveHeaderBg] = useState(false)

  const getIsActiveHeaderBg = useCallback(() => {
    if (posY > 300) {
      setIsActiveHeaderBg(true)
    } else {
      setIsActiveHeaderBg(false)
    }
  }, [posY])

  useEffect(() => {
    getIsActiveHeaderBg()
  }, [getIsActiveHeaderBg])

  return (
    <headerContext.Provider
      value={{
        isScrollUp,
        isScreenGreater,
        isMenuVisible,
        setIsMenuVisible,
        isSearchVisible,
        setIsSearchVisible,
        isActiveHeaderBg,
        posY,
      }}
    >
      {children}
    </headerContext.Provider>
  )
}

export default Header
