import React from 'react'
import { Modal } from 'antd'
// import { FaTimes } from 'react-icons/fa'
import cls from 'classnames'
import { css } from '@emotion/css'

function OwnModal({
  isModalVisible,
  onOk,
  onCancel,
  children,
  footer,
  width,
  closable,
  closeIcon,
  className,
  ...rest
}) {
  const OwnModalStyles = css`
    /* padding: 4rem; */

    .close-icon {
      font-weight: 100;
      font-size: 4rem;
    }
  `
  return (
    <Modal
      closable={closable || false}
      visible={isModalVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer || null}
      width={width}
      className={cls(OwnModalStyles, className)}
      centered={true}
      destroyOnClose
      closeIcon={closeIcon || <span className="close-icon">×</span>}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default OwnModal
