import { css } from '@emotion/css'
import CategoriesWrapper from 'components/CategoriesWrapper/CategoriesWrapper'
import React from 'react'

function OurWork() {
  const OurWorkStyles = css`
    /* background-color: aqua; */
    margin-top: 14rem;
  `

  return (
    <div className={OurWorkStyles}>
      <CategoriesWrapper />
    </div>
  )
}

export default OurWork
