import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import React from 'react'
import ProjectLink from './ProjectLink'
import { useParams } from 'react-router-dom'
import useProjList from 'api-hooks/projects/useProjList'
import OwnResult from 'components/own/OwnResult/OwnResult'

function Category() {
  const CategoryStyles = css``

  const { categoryId } = useParams()
  const { projList, projListPgn } = useProjList(categoryId)
  if (!projList?.length)
    return <OwnResult style={{ margin: '25vw 0' }} title={'No items yet!'} />
  return (
    <div className={CategoryStyles}>
      <Row gutter={[0, 25]}>
        {projList?.map(ele => (
          <Col key={ele?.id} span={24}>
            <ProjectLink ele={ele} />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Category
