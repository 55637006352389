import { Button, Input } from 'antd'
import React from 'react'
import search_img from 'assets/images/icons/search-normal.svg'
import { css } from '@emotion/css'
import cheveron_r_b from 'assets/images/icons/chv-r-b.svg'
import OwnOverlay from 'components/own/OwnOverlay/OwnOverlay'
import useProjCatSrch from 'api-hooks/project-categories/useProjCatSrch'
import { useNavigate } from 'react-router-dom'
import useHeader from 'contexts/hooks/useHeader'

function SearchNav() {
  const { isSearchVisible, setIsSearchVisible } = useHeader()
  const SearchNavStyles = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    z-index: 100;
    .btn-search {
      width: 2.4rem;
    }

    .search-input {
      background-color: #fff;
      position: absolute;
      bottom: -5.5rem;
      right: 0;
      width: 29rem;
      border-bottom: 1px solid #292d32;
      transform: scaleY(${isSearchVisible ? 1 : 0});
      transition: all 0.2s ease-in-out;
      transform-origin: bottom;
      overflow: hidden;

      span {
        background-color: transparent;
      }
      input {
        height: 3.5rem;
        border: none;
        font-size: 1.6rem;
        line-height: 1.5rem;
        color: #000;
        background-color: transparent;

        &::placeholder {
          font-size: 1.4rem;
          text-transform: capitalize;
          color: #7d7d7d;
        }
      }
      button {
        border: none !important;
        img {
          width: 0.8rem;
        }
      }
    }
  `
  const navigate = useNavigate()

  const { projCatSrch } = useProjCatSrch()
  const handleSearch = value => {
    // console.log(value)
    if (!value) return
    projCatSrch(value)
    setIsSearchVisible(false)
    navigate('/our-work')
  }
  return (
    <>
      <div className={SearchNavStyles}>
        <Button
          type="link"
          icon={<img src={search_img} alt="search" />}
          className="btn-search"
          onClick={() => setIsSearchVisible(prev => !prev)}
        />
        <Input.Search
          placeholder="Search"
          onSearch={handleSearch}
          enterButton={
            <Button
              type="link"
              icon={<img src={cheveron_r_b} alt="search" />}
            />
          }
          className="search-input"
        />
      </div>
      <OwnOverlay
        visible={isSearchVisible}
        onClick={() => setIsSearchVisible(false)}
        opacity={0.04}
      />
    </>
  )
}

export default SearchNav
