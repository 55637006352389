import Header from './Header'
import Layout from './Layout'
import Search from './Search'

function ContextProvider({ children }) {
  return (
    <Layout>
      <Header>
        <Search>{children}</Search>
      </Header>
    </Layout>
  )
}

export default ContextProvider
