import { css } from '@emotion/css'
import React from 'react'
import aboutImg from 'assets/images/about.jpg'
// import ImagesWrapper from 'pages/MediaAndAwards/Photos/PhotoDetails/ImagesWrapperOld'
import TextWrapper from 'components/TypeOptionsWrapper/TextWrapper'
import ImageSideText from 'components/TypeOptionsWrapper/ImageSideText'
import ImagesWrapper from 'components/TypeOptionsWrapper/ImagesWrapper'
import ph1 from 'assets/images/ph-small-1.jpg'
import ph3 from 'assets/images/ph-small-3.jpg'
import ph4 from 'assets/images/ph-small-4.jpg'

function AboutUs() {
  const AboutUsStyles = css`
    padding: 17rem 0 6rem;
  `
  return (
    <div className={AboutUsStyles}>
      <ImageSideText
        image={aboutImg}
        text={
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.'
        }
      />

      <div className="container">
        <TextWrapper
          title="Our vision"
          text={[
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            <br />,
            '  Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui. Lorem ipsum dolor sit amet',
            <br />,
            'consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.',
          ]}
          align="center"
        />
      </div>

      <ImagesWrapper images={[ph1, ph3, ph4]} />

      <div className="container">
        <TextWrapper
          title="malesuada sed sapien turpis dui"
          text={[
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.',
            <br />,
            <br />,
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam neque magna nulla donec volutpat volutpat vel massa amet. Ultricies enim, leo, nunc est tristique maecenas. Diam habitasse aliquam malesuada sed sapien turpis dui.',
          ]}
        />
      </div>
    </div>
  )
}

export default AboutUs
