import { css } from '@emotion/css'
import { Col, Image, Row } from 'antd'
import React from 'react'
function ImageSideText({ ele, invert, image, text }) {
  const ImageSideTextStyles = css`
    /* padding: 4rem 0; */
    .image {
      height: 63.5rem;
      .ant-image {
        width: 100%;
        height: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }

    .text-wrapper {
      padding: 7.2rem;
      display: flex;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 204%;
        text-transform: capitalize;
        color: #000000;
        word-break: break-all;
      }
    }
  `

  if (!ele)
    return (
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <div className={ImageSideTextStyles}>
            <Row align="middle">
              <Col xs={24} lg={13} order={invert ? 2 : 0}>
                <div className="image">
                  <Image src={image} />
                </div>
              </Col>
              <Col xs={24} lg={11}>
                <div className="text-wrapper">
                  <span>{text}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  // console.log(ele)
  return (
    <Row gutter={[20, 20]}>
      {ele?.type_options?.map((item, i) => (
        <Col span={24} key={i}>
          <div className={ImageSideTextStyles}>
            <Row align="middle">
              <Col xs={24} lg={13} order={invert ? 2 : 0}>
                <div className="image">
                  <Image src={item?.image} />
                </div>
              </Col>
              <Col xs={24} lg={11}>
                <div className="text-wrapper">
                  <span>{item?.text}</span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default ImageSideText
