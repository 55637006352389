import { Button } from 'antd'
import bars_b from 'assets/images/own/bars_b.svg'
import close_w from 'assets/images/own/close_w.svg'
import { css } from '@emotion/css'
import Nav from './Nav/Nav'
import BrandLogo from 'components/BrandLogo/BrandLogo'
import SearchNav from './Nav/SearchNav/SearchNav'
import cls from 'classnames'
import useHeader from 'contexts/hooks/useHeader'

// START COMPONENT
function Header() {
  const {
    isMenuVisible,
    isScreenGreater,
    isScrollUp,
    setIsMenuVisible,
    posY,
    // isSearchVisible,
    // isActiveHeaderBg,
  } = useHeader()
  // console.log(posY)

  const HeaderStyles = css`
    height: 8rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    transform: translateY(${isScrollUp || posY < 200 ? '0' : '-100%'});
    transition: transform 0.3s ease-in-out;
    background-color: #fff;

    .header-inner {
      display: flex;
      justify-content: space-between;
      transition: height 0.2s ease-out;
    }

    .col {
      display: flex;
      align-items: flex-start;
      gap: 5rem;

      .btn-menu {
        height: 100%;
        width: 2.4rem;
        z-index: 999;
      }
    }
  `

  return (
    <div className={cls(HeaderStyles)}>
      <div className="container">
        <div className="header-inner">
          <BrandLogo />
          <div className="col">
            <Nav />
            <SearchNav />
            {!isScreenGreater && (
              <Button
                type="link"
                icon={<img src={isMenuVisible ? close_w : bars_b} alt="menu" />}
                className="btn-menu"
                onClick={() => setIsMenuVisible(prev => !prev)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header
