import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import useApi from 'hooks/useApi'

function useMsgSend() {
  const navigate = useNavigate()
  const api = useApi()
  const http = async fd => {
    const res = await api.post(`/send/message`, fd)
    if (res?.code === 200) {
      navigate('/')
    }
    return res
  }

  const { mutate } = useMutation(http)

  return { msgSend: mutate }
}

export default useMsgSend
