import { useTranslation } from 'react-i18next'

function useFormRules() {
  const { t } = useTranslation()

  const rules = {
    phoneNumber: [
      {
        required: true,
        message: t('fieldRequired'),
      },
      {
        min: 8,
        message: t('The phone must consist of at least 8 digits'),
      },
      {
        max: 11,
        message: t('The phone cannot be longer than 8 digits'),
      },
      {
        pattern: /^[0-9\b]+$/,
        message: t('phoneNumberPatternRule'),
      },
    ],
    code: [
      {
        required: true,
        message: t('fieldRequired'),
      },
      {
        len: 4,
        message: t('code must be exactly 4 digits'),
      },
    ],
    email: [
      {
        required: true,
        message: t('fieldRequired'),
      },
      {
        type: 'email',
        message: t('emailvalid'),
      },
    ],
    password: [
      {
        required: true,
        message: t('fieldRequired'),
      },
      {
        min: 9,
        message: t('password must be greater than 8 characters'),
      },
      {
        max: 20,
        message: t('password must be less than 20 characters'),
      },
    ],
    confirmPassword: [
      {
        required: true,
        message: t('fieldRequired'),
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (
            !value ||
            getFieldValue('password') === value ||
            getFieldValue('newPassword') === value
          ) {
            return Promise.resolve()
          }
          return Promise.reject(new Error(`${t('twoPasswordsNotSame')}`))
        },
      }),
    ],
    required: [
      {
        required: true,
        message: t('fieldRequired'),
      },
    ],
  }
  return rules
}
export default useFormRules
