import { css } from '@emotion/css'
import { Button, Col, Row, Skeleton } from 'antd'
import useVideoList from 'api-hooks/videos/useVideoList'
import OwnResult from 'components/own/OwnResult/OwnResult'
import React, { useState } from 'react'
import SingleVideo from './SingleVideo'
// import sampleVideo from '../../../assets/videos/sample-video.mp4'
// import sampleVideo2 from '../../../assets/videos/sample-video-2.mp4'

const sampleVideo =
  'https://www.youtube.com/watch?v=sVPYIRF9RCQ&ab_channel=AndyMatthews'
const sampleVideo2 =
  'https://www.youtube.com/watch?v=hS5CfP8n_js&ab_channel=Mr.Monk'

function Videos() {
  const VideosStyles = css`
    .btn-load-more {
      margin-top: 8rem;
    }
  `
  const [videoOnPlay, setVideoOnPlay] = useState(null)
  // const { videosList, videosListPgn } = useVideoList()
  const {
    videosList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useVideoList()
  // console.log(videosList)
  // console.log(hasNextPage)
  return (
    <div className={VideosStyles}>
      <Row gutter={[30, 30]} justify="center">
        {isLoading ? (
          <Skeleton />
        ) : !videosList?.length ? (
          <OwnResult title={'No Data Added!'} />
        ) : (
          videosList?.map(ele => (
            <Col key={ele?.id}>
              <SingleVideo
                ele={ele}
                videoOnPlay={videoOnPlay}
                url={sampleVideo}
                setVideoOnPlay={setVideoOnPlay}
              />
            </Col>
          ))
        )}
      </Row>

      {hasNextPage && (
        <Button
          className="btn-load-more"
          type="primary center"
          ghost
          loading={isFetchingNextPage}
          onClick={fetchNextPage}
        >
          Load More
        </Button>
      )}
    </div>
  )
}

export default Videos
