import { css } from '@emotion/css'
import React from 'react'
import cls from 'classnames'
import MenuNav from './MenuNav/MenuNav'
import BrandLogo from 'components/BrandLogo/BrandLogo'
import useHeader from 'contexts/hooks/useHeader'

function Nav() {
  const { isMenuVisible, setIsMenuVisible, isScreenGreater, isScrollUp, posY } =
    useHeader()

  const NavStyles = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 115vh;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.7);
    width: 0;

    .nav-inner {
      overflow: hidden;
      transition: 0.4s ease-in-out;
      /* width: 24rem; */
      width: 35rem;
      height: 100%;
      padding: 5rem 2rem;
      padding-top: ${isScrollUp || posY < 200 ? '4rem' : '11rem'};

      background-color: #fffefe;
      transform: translateX(-100%);

      display: flex;
      flex-direction: column;
      gap: 4rem;
    }

    &.active {
      width: 100%;
      .nav-inner {
        transform: translateX(0);
      }
    }
  `
  const NavStylesInBig = css`
    .nav-inner {
      /* width: fit-content; */
      display: flex;
      align-items: center;
      gap: 3rem;
    }
  `

  const onClickNavHandler = ({ target, currentTarget }) => {
    if (target === currentTarget || target.alt === 'Loak')
      setIsMenuVisible(false)
  }
  return (
    <nav
      className={cls(isScreenGreater ? NavStylesInBig : NavStyles, {
        active: isMenuVisible || isScreenGreater,
      })}
      onClick={onClickNavHandler}
    >
      <div className="nav-inner">
        {!isScreenGreater && <BrandLogo />}
        <MenuNav />
      </div>
    </nav>
  )
}

export default Nav
