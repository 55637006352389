import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import usePhotosList from 'api-hooks/photos/usePhotosList'
import OwnResult from 'components/own/OwnResult/OwnResult'
import React from 'react'
import { useParams } from 'react-router-dom'
import SinglePhoto from '../SinglePhoto'

function PhotoDetails() {
  const PhotoDetailsStyles = css`
    padding: 15rem 0;

    .page-header {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 4rem 8rem;
      padding: 9rem 0;
      .title {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #000000;
      }

      .colors-wrapper {
        display: flex;
        align-items: center;
        gap: 1.4rem;
        .text {
          font-weight: 300;
          font-size: 1.6rem;
          line-height: 2rem;
          letter-spacing: 0.03em;
          text-transform: capitalize;
          color: #000000;
        }
        .colors-list {
          display: flex;
          align-items: center;
          gap: 0.9rem;

          span {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }
    }
    .page-body {
    }
  `
  const { photoCatId } = useParams()
  const { photosList } = usePhotosList(photoCatId)
  // console.log(photosList)

  if (!photosList?.length)
    return <OwnResult title={'No photos yet!'} style={{ minHeight: '70vh' }} />

  return (
    <div className={PhotoDetailsStyles}>
      <div className="page-header">
        <div className="title">{photosList?.[0]?.photo_category}</div>
        {/* <div className="colors-wrapper">
          <div className="text">Colors :</div>
          <div className="colors-list">
            {photosList?.map(ele => (
              <span
                key={ele?.id}
                style={{ backgroundColor: ele?.colour }}
              ></span>
            ))}
          </div>
        </div> */}
      </div>
      <div className="photos-wrapper">
        <Row gutter={[{ lg: 26 }, 26]}>
          {photosList?.map(ele => (
            <Col key={ele?.id} xs={24} lg={12}>
              <SinglePhoto ele={ele} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default PhotoDetails
