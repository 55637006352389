import { css } from '@emotion/css'
import React from 'react'

function SingleAward({ ele }) {
  const SingleAwardStyles = css`
    width: 16rem;
    margin: auto;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.7rem;
    img {
      width: 12rem;
      height: 11rem;
      object-fit: contain;
    }

    .title {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
    }
  `
  return (
    <div className={SingleAwardStyles}>
      <img src={ele?.image} alt="Award" />
      <div className="title">{ele?.name}</div>
    </div>
  )
}

export default SingleAward
