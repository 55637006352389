/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

function useIsScreenGreater(size = 992) {
  const [isGreater, setIsGreater] = useState(false)

  const observeScreen = () => {
    if (window.innerWidth >= size) setIsGreater(true)
    else setIsGreater(false)
  }
  useEffect(() => {
    observeScreen()
    window.addEventListener('resize', observeScreen)

    return () => {
      window.removeEventListener('resize', observeScreen)
    }
  }, [size])

  return isGreater
}
export default useIsScreenGreater
