import { headerContext } from 'contexts/Header'
import { useContext } from 'react'

function useHeader() {
  const {
    isScrollUp,
    isScreenGreater,
    isMenuVisible,
    setIsMenuVisible,
    isSearchVisible,
    setIsSearchVisible,
    isActiveHeaderBg,
    posY,
  } = useContext(headerContext)
  return {
    isScrollUp,
    isScreenGreater,
    isMenuVisible,
    setIsMenuVisible,
    isSearchVisible,
    setIsSearchVisible,
    isActiveHeaderBg,
    posY,
  }
}

export default useHeader
