import React from 'react'
import OwnSlider from 'components/own/OwnSlider/OwnSlider'
import ReactPlayer from 'react-player'
import { css } from '@emotion/css'
import useSliderList from 'api-hooks/slider/useSliderList'
import { Tooltip } from 'antd'

function HomeCover() {
  const HomeCoverStyles = css`
    position: relative;
    .slider-item {
      height: 100vh;
      max-height: 100vw;
      background-color: #000;

      .slider-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .social-wrapper {
      position: absolute;
      bottom: 4vw;
      left: 4vw;

      display: flex;
      align-items: center;
      gap: 1rem;
      a {
        display: inline-block;
        padding: 0.5rem;
        font-weight: 600;
        line-height: normal;
        font-size: 1.3rem;
        letter-spacing: 0.06em;
        color: #fff;
        text-transform: capitalize;
      }
    }
  `

  const { sliderList } = useSliderList()
  // console.log(sliderList)

  return (
    <div className={HomeCoverStyles}>
      <OwnSlider>
        {sliderList?.map(ele => {
          if (ele?.type === 'video')
            return (
              <li key={ele?.id}>
                <div className="slider-item">
                  <ReactPlayer
                    url={ele?.attachment}
                    playing
                    muted
                    loop
                    width="100%"
                    height="100%"
                  />
                </div>
              </li>
            )

          return (
            <li key={ele?.id}>
              <div className="slider-item">
                <img
                  className="slider-image"
                  src={ele?.attachment}
                  alt="cover"
                />
              </div>
            </li>
          )
        })}
      </OwnSlider>
      <div className="social-wrapper">
        <Tooltip title="Facebook">
          <a
            href="https://www.facebook.com/loakdesigns/"
            target="_blank"
            rel="noreferrer"
          >
            fb
          </a>
        </Tooltip>
        <Tooltip title="Instagram">
          <a
            href="https://www.instagram.com/loakdesigns"
            target="_blank"
            rel="noreferrer"
          >
            in
          </a>
        </Tooltip>
        <Tooltip title="behance">
          <a href="/" target="_blank" rel="noreferrer">
            be
          </a>
        </Tooltip>
        <Tooltip title="pinterest">
          <a href="/" target="_blank" rel="noreferrer">
            pi
          </a>
        </Tooltip>
      </div>
    </div>
  )
}

export default HomeCover
