import { css } from '@emotion/css'
import { Button, Col, Input, Row, Form } from 'antd'
import loc_img from 'assets/images/icons/loc.png'
import cal_img from 'assets/images/icons/call.svg'
import msg_img from 'assets/images/icons/msg.svg'
import fac_img from 'assets/images/icons/facebook.svg'
import you_img from 'assets/images/icons/youtube.svg'
import ins_img from 'assets/images/icons/instagram.svg'
import { Link } from 'react-router-dom'
import SubscribeForm from 'components/SubscribeForm/SubscribeForm'

function Footer() {
  const FooterStyles = css`
    padding: 6rem 0;
    background-color: #fff;

    .box {
      max-width: 41rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.4rem;
    }
    .title {
      font-size: 1.5rem;
      color: #7d7d7d;
    }
    .text {
      font-size: 1.2rem;
      color: #000;
    }
    .icon {
      width: 2.4rem;
    }

    .about-us {
      .title {
        margin-bottom: 2.2rem;
      }
      ul.links-list {
        display: flex;
        flex-direction: column;
        gap: 2.2rem;
        .text {
          text-transform: capitalize;
        }
      }
    }
    ul.contact-list {
      display: flex;
      flex-direction: column;
      gap: 4.2rem;
      li {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        .text {
          line-height: 1.9rem;
        }
      }
    }

    .subscribe {
      .text {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.58;
        margin-bottom: 2.8rem;
      }
    }

    .find-us {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      .text {
        font-weight: 500;
      }
      .socail-list {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        a {
        }
      }
    }
  `

  return (
    <div className={FooterStyles}>
      <div className="container">
        <Row gutter={[100, 50]} justify="space-between">
          <Col className="box">
            <div className="about-us">
              <div className="title ff-1">About us</div>
              <ul className="links-list">
                <li>
                  <Link to="/about-us" className="text">
                    about
                  </Link>
                </li>
                <li>
                  <Link to="/our-work" className="text">
                    Our Works
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text">
                    Privacy policy
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="text">
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col className="box">
            <ul className="contact-list">
              <li>
                <img className="icon" src={loc_img} alt="location" />
                <a href="/" className="text">
                  65 Mossadak, Ad Doqi, Dokki, Giza Governorate Cairo, Cairo
                  Governorate, Egypt 11211
                </a>
              </li>
              <li>
                <img className="icon" src={msg_img} alt="msg" />
                <a href="mailto:" className="text">
                  info@lo-ak.com
                </a>
              </li>
              <li>
                <img className="icon" src={cal_img} alt="call" />
                <a href="tel:" className="text">
                  +20 01234567899
                </a>
              </li>
            </ul>
          </Col>
          <Col className="box">
            <div className="subscribe">
              <div className="text">Subscribe to our newsletter</div>
              <SubscribeForm />
            </div>
            <div className="find-us">
              <div className="text">Find us on</div>
              <div className="socail-list">
                <a href="/">
                  <img className="icon" src={fac_img} alt="facebook" />
                </a>
                <a href="/">
                  <img className="icon" src={you_img} alt="youtube " />
                </a>
                <a href="/">
                  <img className="icon" src={ins_img} alt="instagram" />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Footer
