import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import useApi from 'hooks/useApi'

function useSubscriber() {
  const api = useApi()
  const http = async fd => {
    const res = await api.post('/store/subscriber', fd)
    return res
  }
  const { mutate } = useMutation(http, {
    onSuccess: res => {
      // console.log(res)
      if (res?.code === 101) {
        toast.success(res?.validation?.[0])
        return
      }
      toast.success(res?.message)
    },
  })

  return { subscriber: mutate }
}

export default useSubscriber
