import { useRoutes } from 'react-router-dom'

import Home from './pages/Home/Home'
import OwnNotFound from './components/own/OwnNotFound/OwnNotFound'
import Category from 'pages/Category/Category'
import Project from 'pages/Category/Project/Project'
import OurWork from 'pages/OurWork/OurWork'
import MediaAndAwards from 'pages/MediaAndAwards/MediaAndAwards'
import PhotoDetails from 'pages/MediaAndAwards/Photos/PhotoDetails/PhotoDetails'
import SinglePhotoDetails from 'pages/MediaAndAwards/Photos/PhotoDetails/SinglePhotoDetails'
import Blogs from 'pages/Blogs/Blogs'
import BlogDetails from 'pages/Blogs/BlogDetails/BlogDetails'
import AboutUs from 'pages/AboutUs/AboutUs'
import ContactUs from 'pages/ContactUs/ContactUs'

function RoutesWrapper() {
  // const [authenticated] = useState(false)

  const routes = useRoutes([
    {
      path: '*',
      element: <OwnNotFound />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/about-us',
      element: <AboutUs />,
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
    },
    {
      path: '/our-work',
      children: [
        {
          index: true,
          element: <OurWork />,
        },
        {
          path: ':categoryId',
          children: [
            {
              index: true,
              element: <Category />,
            },
            {
              path: ':projectId',
              element: <Project />,
            },
          ],
        },
      ],
    },
    {
      path: '/media-and-awards',
      children: [
        {
          index: true,
          element: <MediaAndAwards />,
        },
        {
          path: ':keyTab',
          element: <MediaAndAwards />,
        },
        {
          path: 'photos/:photoCatId',
          children: [
            {
              index: true,
              element: <PhotoDetails />,
            },
            {
              path: ':photoId',
              element: <SinglePhotoDetails />,
            },
          ],
        },
      ],
    },
    {
      path: '/blogs',
      children: [
        {
          index: true,
          element: <Blogs />,
        },
        {
          path: ':blogId',
          element: <BlogDetails />,
        },
      ],
    },
  ])

  return routes
}

export default RoutesWrapper
