import { css } from '@emotion/css'
import { Col, Row } from 'antd'
import OwnModal from 'components/own/OwnModal/OwnModal'
import React from 'react'
import subImg from 'assets/images/contact.jpg'
import SubscribeForm from 'components/SubscribeForm/SubscribeForm'
import { useEffect } from 'react'
import useLayout from 'contexts/hooks/useLayout'

function SubscribePopup() {
  const SubscribePopupStyles = css`
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-close {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.5rem;
    }
    .close-icon {
      color: #fff;
      font-size: 3.4rem;
    }

    .sub-wrapper {
      height: 54.7rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4rem 2rem;

      .title {
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 1.12;
        text-transform: uppercase;
        color: #000;
        margin-bottom: 3.5rem;
      }
      .sub-title {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.36;
        text-transform: capitalize;
        color: #000;
        margin-bottom: 1.1rem;
      }
      .text {
        max-inline-size: 35ch;
        text-align: center;
        margin-bottom: 5.2rem;
      }
    }

    .image-wrapper {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  `
  const {
    isSmlScr,
    isSubscribePopup,
    openSubscribePopup,
    closeSubscribePopup,
  } = useLayout()

  useEffect(() => {
    setTimeout(openSubscribePopup, 10000)
  }, [])

  return (
    <OwnModal
      className={SubscribePopupStyles}
      isModalVisible={isSubscribePopup}
      onCancel={closeSubscribePopup}
      closable
      width={isSmlScr ? 890 : 500}
    >
      <div className="modal-inner">
        <Row>
          <Col xs={24} md={12}>
            <div className="sub-wrapper">
              <div className="title">Welcome to LOAK</div>
              <div className="sub-title">Subscribe to our newsletter</div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh
                tortor mauris venenatis, duis nibh lacinia hac mauris{' '}
              </div>
              <SubscribeForm />
            </div>
          </Col>
          {isSmlScr && (
            <Col xs={24} md={12}>
              <div className="image-wrapper">
                <img src={subImg} alt="Subscribe" />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </OwnModal>
  )
}

export default SubscribePopup
