import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { css } from '@emotion/css'
import cls from 'classnames'
import Dropdown from './Dropdown'
import useProjCatList from 'api-hooks/project-categories/useProjCatList'
import useHeader from 'contexts/hooks/useHeader'

// const rootSubmenuKeys = ['our-work']
const rootSubmenuKeys = [
  'Residential',
  'hospitality',
  'Corporate',
  'Commercial',
  'NGOs',
]

function getItem(label, key, children, type) {
  return {
    label,
    key,
    children,
    type,
  }
}

//
function MenuNav() {
  const { setIsMenuVisible, isScreenGreater } = useHeader()
  const MenuWrapperStyles = css``
  const MenuNavStyles = css`
    background-image: linear-gradient(
      to bottom,
      #fffefe 0%,
      #fffefe 0%,
      #fffefe 0%
    );
    margin-left: -24px;
    width: calc(100% + 24px);
    border: none;
    max-height: 75vh;
    overflow-x: clip;
    overflow-y: auto;
    .ant-menu-title-content {
      font-size: 1.6rem;
      font-weight: normal;
      text-transform: uppercase;
      /* letter-spacing: 0.16rem; */
      color: #000;
    }
  `
  const MenuNavStylesOnBig = css`
    background-color: transparent;
    border: none;
    .ant-menu-item-selected::after {
      display: none;
    }
    .ant-menu-submenu-title {
      /* border-bottom: ${`attr(data-menu-id)`} 2px solid #000; */
    }
    .ant-menu-title-content {
      font-family: 'SafiraMarchPersonalUse', -apple-system, BlinkMacSystemFont,
        Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
      font-size: 0.8rem;
      font-weight: normal;
      text-transform: capitalize;
      letter-spacing: 0.16rem;
      color: #000;
    }
  `
  const navigate = useNavigate()
  const [openKeys, setOpenKeys] = useState([])
  const { projCatList } = useProjCatList()

  const items = [
    getItem(
      'Our Work',
      'our-work',
      projCatList?.map(cat => {
        return getItem(
          cat?.name,
          `/our-work/${cat?.id}`,
          cat?.children?.length
            ? cat?.children?.map(sub =>
                getItem(sub?.name, `/our-work/${cat?.id}/${sub?.id}`)
              )
            : null
        )
      })
    ),
    getItem(
      <span>
        Media <i className="special-character">&</i> Awards
      </span>,
      'media-and-awards'
    ),
    getItem('blog', 'blogs'),
    getItem('contact us', 'contact-us'),
    getItem('about', 'about-us'),
  ]

  const onOpenChange = keys => {
    // setOpenKeys(keys)
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      // setOpenKeys(keys.concat('blog'))
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? ['our-work', latestOpenKey] : [])
    }
  }

  const onClick = e => {
    //    console.log('click.... ', e)
    // setCurrent(e.key);
    setIsMenuVisible(false)

    navigate(e?.key)
    // const path = e?.keyPath?.reverse().join('/')
    // console.log(path)
    // navigate(path)
  }

  return (
    <div className={MenuWrapperStyles}>
      <Menu
        onClick={onClick}
        className={cls(isScreenGreater ? MenuNavStylesOnBig : MenuNavStyles)}
        triggerSubMenuAction="hover"
        disabledOverflow={true}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        mode={isScreenGreater ? 'horizontal' : 'inline'}
        items={items}
      />
      {isScreenGreater && <Dropdown items={projCatList} openKeys={openKeys} />}
    </div>
  )
}
export default MenuNav
