import ImageSideText from './ImageSideText'
import ImagesWrapper from './ImagesWrapper'
import TextWrapper from './TextWrapper'

function TypeOptionsWrapper({ ele }) {
  //   const TypeOptionsWrapperStyles = css``
  //   console.log(ele)

  if (
    ele?.type === 'one image' ||
    ele?.type === 'One image' ||
    ele?.type === 'Three images' ||
    ele?.type === 'One image Left with a two third image Right.' ||
    ele?.type === 'A two third image Left with one image Right.' ||
    ele?.type === 'A two third image Left with one image Right'
  )
    return <ImagesWrapper ele={ele} />

  if (ele?.type === 'One text') return <TextWrapper ele={ele} />

  if (ele?.type === 'Left image with Right text')
    return <ImageSideText ele={ele} />

  if (ele?.type === 'Left text with Right image')
    return <ImageSideText ele={ele} invert />

  return <h1>Type not listed!</h1>
}

export default TypeOptionsWrapper
